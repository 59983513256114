import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/Home/Home';
import NavBar from './components/NavBar/NavBar';
import Project from './pages/Project/Project';
import About from './pages/About/About';
import { library } from '@fortawesome/fontawesome-svg-core';
import {fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Portfolio from './pages/Portfolio/Portfolio';

// faNodeJs, faReact, faAdobe, faLanguage, faMicrochip, faTerminal
library.add(fas, fab)

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar/>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' exact component={About} />
          <Route path='/portfolio' exact component={Portfolio} />
          <Route path='/projects/:id' exact component={Project} />
          <Redirect to='/' />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
