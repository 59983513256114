import React from 'react';
import {
    faMicrochip,
    faLanguage,
    faBoxOpen,
    faEnvelopeOpenText,
    faCodeBranch
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faNode, faAdobe, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import csharp from './resources/images/csharp.svg'

export default {
    'faAdobe': <FontAwesomeIcon icon={faAdobe} className='icon'/>,
    'faNode': <FontAwesomeIcon icon={faNode} className='icon' />,
    'faMicrochip': <FontAwesomeIcon icon={faMicrochip} className='icon' />,
    'faReact': <FontAwesomeIcon icon={faReact} className='icon'/>,
    'faLanguage': <FontAwesomeIcon icon={faLanguage} className='icon' />,
    'faBoxOpen': <FontAwesomeIcon icon={faBoxOpen} className='icon' />,
    'csharp': <img alt='' src={csharp} style={{width:"100%", fill: "#fff"}}></img>,
    'faEnvelopeOpenText':<FontAwesomeIcon icon={faEnvelopeOpenText} size='3x' fixedWidth />,
    'faCodeBranch': <FontAwesomeIcon icon={faCodeBranch} size='3x' fixedWidth />,
    'faLinkedin': <FontAwesomeIcon icon={faLinkedin} size='3x' fixedWidth/>
};