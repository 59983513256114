import React, { useEffect } from 'react';
import './Home.scss'
import Projects from '../../components/Projects/Projects';
import Particles from 'react-particles-js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpenText, faCodeBranch } from '@fortawesome/free-solid-svg-icons'

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className='home'>
                <div className='top'>
                        <Particles
                            params={{
                                particles: {
                                    number: {
                                        value: 30,
                                    },
                                    size: {
                                        value: 3,
                                    },
                                    color: {
                                        value: "#000",
                                    },
                                    lineLinked:{
                                        color:{
                                            value: "#000"
                                        }
                                    }
                                },
                            }}
                            style={{position:"absolute", zIndex: "-1"}}
                        />
                    <div className='wrapper'>
                        <div className='box'>
                            <div className='content'>
                                <span className='hello'>
                                    Hi, I'm <span className='name'>Tim Speetjens</span>
                                </span>
                                <p className='slogan'>
                                    multi-lingual student in Software Engineering
                                </p>
                                <div className='socials'>
                                    <a aria-label='LinkedIn Profile' className='socials-link' href='https://www.linkedin.com/in/tim-speetjens/'><FontAwesomeIcon icon={faLinkedin} size='3x'/></a>
                                    <a aria-label='GitHub.com/aradox' className='socials-link' href='https://github.com/aradox'><FontAwesomeIcon icon={faCodeBranch} size='3x' fixedWidth /></a>
                                    <a aria-label='Mail to tim'className='socials-link' href='mailto:me@timspeetjens.nl'><FontAwesomeIcon icon={faEnvelopeOpenText} size='3x' fixedWidth /></a>
                                </div>
                            </div>
                        </div>
                        <div className='img-box'>
                            <div className='content'>
                                <img alt='' src='/code.svg' />
                            </div>
                        </div>                        
                    </div>
                    <div className="arrow-icon-container">
                        <a href="/#projects" aria-label='link to projects'>
                            <div className="arrow-down">
                                <img className="arrow-icon" src="/arrow-down.svg" alt="" />
                            </div>
                        </a>
                    </div>
                </div>
                <a id="projects" href="/#" aria-label='link to projects'><i></i></a>
                <Projects />
            </div>
        </>
    )
}
