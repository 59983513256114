import React, { useState, useEffect } from 'react';
import './About.scss';
import data from '../../data/data.json';
import iconMap from '../../IconMapping';

export default function About(props) {
    const [about] = useState(data.about)
    const [selectedSkill, setSelectedSkill] = useState(about.skills[0])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='about'>
            <div className='top'>
                <div className='wrapper'>
                    <div className='title'><span>About</span> <span className='name'>TIM</span></div>
                </div>
            </div>
            <div className='wrapper'>
                <h2>Small introduction</h2>
                <div className='text'>
                    {about.aboutText.split('\n').map(function (item, key) {
                        return (
                            <div key={key} style={{ marginBottom: '0.5em' }}>
                                {item}
                            </div>
                        )
                    })}
                    <div className='socials'>
                        <a aria-label='LinkedIn Profile' className='socials-link' href='https://www.linkedin.com/in/tim-speetjens/'>{iconMap['faLinkedin']}</a>
                        <a aria-label='GitHub.com/aradox' className='socials-link' href='https://github.com/aradox'>{iconMap['faCodeBranch']}</a>
                        <a aria-label='Mail to tim' className='socials-link' href='mailto:me@timspeetjens.nl'>{iconMap['faEnvelopeOpenText']}</a>
                    </div>
                </div>
                <h2>Portfolio</h2>
                <h3 style={{cursor:"pointer"}} onClick={() => props.history.push('/portfolio')}>Look at my Portfolio</h3>
                <h2>Skills</h2>
                <div className='skills'>
                    <div className='icons'>
                        {about.skills.map((skill, index) => (
                            <a key={index} href='#skill' onClick={()=> setSelectedSkill(skill)}>
                                <div className='icon'>
                                    {iconMap[skill.icon]}
                                    <span className='name'>{skill.name}</span>
                                </div>
                            </a>
                        ))}
                    </div>
                    <a aria-label='skill' aria-hidden="true" id='skill' href='/#'><i></i> </a>
                    <div className='skill-text'>
                        <p>
                            {selectedSkill.description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
