import React, { useEffect, useState } from 'react';
import './Project.scss';
import data from '../../data/data.json';

export default function Project(props) {
    const [project, setProject] = useState({
        images:[]
    })
    useEffect(() => {
        window.scrollTo(0, 0)
        function findProjectByid(id){
            let project = data.projects.find(p => p.id === id)            
            return project;
        }
        setProject(findProjectByid(parseInt(props.match.params.id)));
    }, [project, setProject, props])

    return (
        <div className='project'>            
            <div className='top'>
                <div className='wrapper'>
                    <div className='title'>
                        {project.name}
                    </div>                    
                </div>
            </div>
            <div className='wrapper'>
                <div className='description'>
                    <h1>Project description</h1>
                    <p>{project.description}</p>
                </div>
            </div>
            <div className='wrapper'>
                <div className='image-box'>
                    <h1>Images</h1>
                    <div className='images'>
                        {project.images.map((i, index) => (
                            <div key={index} className='image'>
                                <img alt='' src={i.src}></img>
                                <p>{i.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
