import React, { useState } from 'react';
import './NavBar.scss';
import { Link } from 'react-router-dom';

export default function NavBar(props) {
    const [menu, setMenu] = useState(false)
    function toggleMenu() {
        setMenu(!menu);
    }
    function renderLinks(){
        return(
            <>
                <Link to='/portfolio' onClick={() => setMenu(false)} className='item'>Portfolio</Link>
                <Link to='/about' onClick={() => setMenu(false)} className='item'>About</Link>
                {/* <Link to='/cv' className='item'>CV</Link> */}
            </>
        )
    }
    return (
        <>
            <div className='nav-bar'>
                <div className='nav-left'>
                    <Link to='/' className='title' onClick={() => window.scrollTo(0, 0)}>Tim Speetjens</Link>
                </div>
                <div className='nav-right'>
                    {renderLinks()}
                </div>
                <div className='icon' onClick={() => toggleMenu()}>
                    Menu
                </div>
            </div>
            {
                menu ?
                    <div className='mobile-nav'>
                        {renderLinks()}
                    </div>
                    : null
            }
        </>
    )
}
