import React, { useState } from 'react'
import data from '../../data/data.json';
import './Projects.scss'
import { Link } from 'react-router-dom';

export default function Projects() {
    const [projects] = useState(data.projects);

    return (
        <div className='projects'>
            <div className='wrapper'>
                <h1 className='title'>Projects I have worked on</h1>
                {projects.map(p => (
                    <Link key={p.id} to={p.link}>
                        <div  className='project'>
                            <span className='title'>{p.name}</span>
                            <div className='content'>
                                <div className='text'>
                                    {p.description.split('\n').map(function (item, key) {
                                        return (
                                            <div key={key} style={{ marginBottom:'0.5em'}}>
                                                {item}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='project-img'>
                                    <img  src={p.images[0].src} alt={p.images[0].description} />
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}
