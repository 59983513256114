import React, { useEffect } from 'react'
import Projects from '../../components/Projects/Projects'

export default function Portfolio() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Projects/>
        </div>
    )
}
